import { createTheme } from "@mui/material";
import { createContext, useEffect, useState } from "react";

export const BACKEND_URL = "https://banzai.sisc.ro";

/**
 * @deprecated Foloseste siteContext in schimb
 */
export const userContext = createContext({});

export default function Context(props) {
    const [ userData, setUserData ] = useState(null);

    useEffect(() => {
        const loadUser = async () => {
            const req = await fetch(`${BACKEND_URL}/api/user`, { credentials: 'include' });
            const user = await req.json();
            if(user && Object.keys(user).length != 0) {
                setUserData(user);
            }
        };

        loadUser();
    }, []);

    return (<userContext.Provider value={userData}>{props.children}</userContext.Provider>);
}
