import { Class, Gavel, Group, LooksOne, LooksTwo, Schedule } from "@mui/icons-material";
import { Avatar, Card, CardContent, Divider, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { BACKEND_URL } from "../../Context";
import { TimeClock } from "@mui/x-date-pickers";
import dayjs from "dayjs";

function RecruitCard({ user }) {
    const [ userTeam, setUserTeam ] = useState(null);
    const [ userInterview, setUserInterview ] = useState(null);

    useEffect(() => {
        const loadUserTeam = async () => {
            try {
                const req = await fetch(`${BACKEND_URL}/api/team/${user.recruit.teamId}`, { credentials: 'include' });
                const team = await req.json();
                setUserTeam(team);
            } catch(error) {
                console.error(error.message);
            }
        };

        const loadUserInterview = async () => {
            try {
                const req = await fetch(`${BACKEND_URL}/api/interview/${user.recruit.interviewId}`, { credentials: 'include' });
                const interview = await req.json();
                if(interview.date)
                    setUserInterview(interview);
                else
                    setUserInterview(null);
            } catch(error) {
                console.error(error.message);
            }
        };

        loadUserTeam();
        loadUserInterview();
    }, [ user ]);

    return (
        <Card sx={{ width: '45%', height: '825px' }} className="view-user-card">
            <CardContent>
                <Typography variant="h5">Recruit data</Typography>
                <List style={{ width: '100%' }} aria-label="recruit-info">
                    <ListItem>
                        <ListItemAvatar><Avatar><Schedule /></Avatar></ListItemAvatar>
                        <ListItemText primary="Programmed interview" secondary={
                            (userInterview)
                                ? dayjs(userInterview.date).format(`dddd, DD MMMM YYYY (HH:mm)`)
                                : `Not scheduled    `
                        }/>
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemAvatar><Avatar><Group /></Avatar></ListItemAvatar>
                        <ListItemText primary="Team" secondary={(userTeam) ? userTeam.name : 'Not assigned'} />
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar><Avatar><LooksOne /></Avatar></ListItemAvatar>
                        <ListItemText primary="First option" secondary={
                            (user.recruit.firstOption) 
                                ? user.recruit.firstOption
                                : 'Not selected'
                        } />
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar><Avatar><LooksTwo /></Avatar></ListItemAvatar>
                        <ListItemText primary="Second option" secondary={
                            (user.recruit.secondOption)
                                ? user.recruit.secondOption
                                : 'Not selected'
                        } />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemAvatar><Avatar><Class /></Avatar></ListItemAvatar>
                        <ListItemText primary="First option homework" secondary={
                            (user.recruit.firstOptHomework)
                                ? 'Uploaded'
                                : 'Not uploaded'
                        }/>
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar><Avatar><Class /></Avatar></ListItemAvatar>
                        <ListItemText primary="Second option homework" secondary={
                            (user.recruit.secondOptHomework)
                                ? 'Uploaded'
                                : 'Not uploaded'
                        }/>
                    </ListItem>
                </List>
                <Divider />
                <List style={{ width: '100%' }} aria-label="recruit-decision-info">
                    <ListItem>
                        <ListItemAvatar><Avatar><Gavel /></Avatar></ListItemAvatar>
                        <ListItemText primary="Decision for first option" secondary={"NOT IMPLEMENTED"} />
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar><Avatar><Gavel /></Avatar></ListItemAvatar>
                        <ListItemText primary="Decision for second option" secondary={"NOT IMPLEMENTED"} />
                    </ListItem>
                </List>
            </CardContent>
        </Card>
    )
}

export default RecruitCard;