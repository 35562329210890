import { Paper, Typography } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { Delete, Edit, RemoveRedEye } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserApi } from "../Utils";
import { siteContext } from "./Utils/SiteContext";

function UsersList({ title, list, showAccountType, showDeptOptions, hideUserId, extraColumns }) {
    const { user, successToast, errorToast } = useContext(siteContext);
    const navigate = useNavigate();

    const deleteUser = async (id) => {
        const user = await UserApi.getUser(id);
        const res = await UserApi.deleteUser(id);
        if(res.status == 200) {
            successToast(`User "${user.firstName} ${user.lastName}" deleted.`);
        } else {
            errorToast(`Failed to delete user "${user.firstName} ${user.lastName}".`);
        }
    };

    const actionButtons = { 
        headerName: 'Actions', 
        type: 'actions', 
        field: 'actions',
        flex: 1,
        maxWidth: 120,
        getActions: (params) => [
            <GridActionsCellItem
                icon={<RemoveRedEye />}
                label="View user"
                onClick={() => { navigate(`/viewUser?id=${params.id}`) }}
            />,
            <GridActionsCellItem
                icon={<Edit />}
                label="Edit user"
            />,
            <GridActionsCellItem
                icon={<Delete />}
                color="error"
                label="Delete user"
                onClick={() => { 
                    if(user.rank == 'admin') {
                        deleteUser(params.id);
                    } else {
                        errorToast("Insufficient permissions.");
                    }
                }}
            />
        ]
    };

    const baseColumns = [
        { headerName: 'ID', field: 'id', flex: 1, maxWidth: 60, headerAlign: 'center' },
        { headerName: 'First name', field: 'firstName', flex: 1, minWidth: 160, hideable: false, headerAlign: 'center' },
        { headerName: 'Last name', field: 'lastName', flex: 1, minWidth: 160, hideable: false, headerAlign: 'center' },
        { headerName: 'Email', field: 'email', flex: 1, maxWidth: 300, hideable: false, headerAlign: 'center' },
        { 
            headerName: 'Phone', 
            field: 'phone', 
            renderCell: (params) =>
                (!params.value)
                    ? 'Not set'
                    : params.value,
            flex: 1,
            maxWidth: 160,
            headerAlign: 'center'
        },
        {
            headerName: 'Account type',
            field: 'rank',
            renderCell: (params) =>
                (params.value == 'admin')
                    ? 'Administrator'
                    : (params.value == 'siscot')
                        ? 'Member'
                        : 'Recruit',
            flex: 1,
            maxWidth: 120,
            headerAlign: 'center'
        },
        {
            headerName: 'First option',
            field: 'firstOption',
            valueGetter: (params, row) => 
                (row.recruit && row.recruit.firstOption)
                    ? row.recruit.firstOption
                    : 'Not set',
            flex: 1,
            headerAlign: 'center',
            maxWidth: 140
        },
        {
            headerName: 'Second option',
            field: 'secondOption',
            valueGetter: (params, row) => 
                (row.recruit && row.recruit.secondOption)
                    ? row.recruit.secondOption
                    : 'Not set',
            flex: 1,
            headerAlign: 'center',
            maxWidth: 140
        },
    ];

    const columns = (extraColumns)
                        ? [ baseColumns.concat(extraColumns), actionButtons ] 
                        : [ ...baseColumns, actionButtons ];

    return (
        <Paper sx={{ width: '90%', minHeight: '70vh' }}>
            <DataGrid 
                sx={{ border: 0 }}
                columns={columns}
                rows={list}
                initialState={{ 
                    pagination: { page: 0, pageSize: 5 },
                    columns: {
                        columnVisibilityModel: {
                            firstOption: (showDeptOptions) ? true : false,
                            secondOption: (showDeptOptions) ? true : false,
                            rank: (showAccountType) ? true : false,
                            id: (hideUserId) ? false : true  
                        }
                    }
                }}
                disableRowSelectionOnClick
                disableColumnSelector
                autosizeOptions={{
                    includeOutliers: true,
                    includeHeaders: false
                }}
                autosizeOnMount
            />
        </Paper>
    );
}

export default UsersList;