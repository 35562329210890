import { useContext } from "react";
import { BACKEND_URL } from "./Context";
import { createTheme } from "@mui/material";

export const InterviewApi = {
    getInterview: async (id) => {
        const req = await fetch(`${BACKEND_URL}/api/interview/${id}`, { credentials: 'include' });
        return req.json();
    }
};

export const TeamsApi = {
    getAllTeams: async () => {
        const req = await fetch(`${BACKEND_URL}/api/team`, { credentials: 'include' });
        return req.json();
    },

    getTeam: async (id) => {
        const req = await fetch(`${BACKEND_URL}/api/team/${id}`, { credentials: 'include' });
        return req.json();
    },

    generateNewTeams: async (firstOptionWeight, secondOptionWeight, teamSizeWeight) => {
        return fetch(`${BACKEND_URL}/api/team`, {
            credentials: 'include',
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                firstOptionWeight: firstOptionWeight,
                secondOptionWeight: secondOptionWeight,
                teamSizeWeight: teamSizeWeight
            })
        });
    }
};

export const PhaseApi = {
    getCurrentPhase: async () => {
        const req = await fetch(`${BACKEND_URL}/api/phase`, { credentials: 'include' });
        if(req.status === 200) {
            return req.json();
        } else {
            return null;
        }
    },

    getPhase: async (id) => {
        const req = await fetch(`${BACKEND_URL}/api/phase/${id}`, { credentials: 'include' });
        return req.json();
    },

    createPhase: async (name, startDate, endDate) => {
        return fetch(`${BACKEND_URL}/api/phase`, {
            credentials: 'include',
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                name: name,
                startDate: startDate.toString(),
                endDate: endDate.toString()
            })
        });
    }
};

export const UserApi = {
    getUser: async (id) => {
        if(!id) {
            const req = await fetch(`${BACKEND_URL}/api/user`, { credentials: 'include' });
            return req.json();
        }

        const req = await fetch(`${BACKEND_URL}/api/user/${id}`, { credentials: 'include' });
        return req.json();
    },

    getAllUsers: async () => {
        const req = await fetch(`${BACKEND_URL}/api/user/all`, { credentials: 'include' });
        return req.json();
    },

    deleteUser: async (id) => {
        return fetch(`${BACKEND_URL}/api/user/${id}`, {
            credentials: 'include',
            method: 'DELETE'
        });
    },

    logout: async () => {
        return fetch(`${BACKEND_URL}/api/google/logout`, { credentials: 'include' });
    }
};

export const FeedbackApi = {
    getUserFeedback: async (user) => {
        const id = (typeof user == 'number') ? user : user.id;
        const req = await fetch(`${BACKEND_URL}/api/user/${id}/feedback`, { credentials: 'include' });
        return req.json();
    },

    deleteById: async (id) => {
        return fetch(`${BACKEND_URL}/api/feedback/${id}`, {
            credentials: 'include',
            method: 'DELETE'
        });
    },

    createFeedback: async (parent, user, recruit, message) => {
        const phase = await PhaseApi.getCurrentPhase();
    
        return fetch(`${BACKEND_URL}/api/feedback`, {
            credentials: 'include',
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                parent: (parent) ? ((typeof parent == 'number') ? parent : parent.id) : null,
                phaseId: (phase) ? phase.id : null,
                senderId: (typeof user == 'number') ? user : user.id,
                recruitId: (typeof recruit == 'number') ? recruit : recruit.id,
                content: message
            })
        });
    }
};
