import { Avatar, Button, Card, CardContent, CardMedia, Divider, List, ListItem, ListItemAvatar, ListItemText, styled, Typography } from "@mui/material";
import { BACKEND_URL, userContext } from "../../Context";
import { CloudUpload, Edit, Email, Person, Phone } from "@mui/icons-material";
import { useContext } from "react";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

function UserCard({ user }) {
    const siteUser = useContext(userContext);

    return (
        <Card 
            sx={{ width: '45%', height: '825px' }} 
            className="view-user-card card-user"
        >
            <CardMedia
                component="img"
                sx={{ marginTop: 4, height: '50%', objectFit: 'contain' }}
                image={`${BACKEND_URL}/api/user/${user.id}/avatar`}
                title="User avatar"
            />

            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {user.firstName} {user.lastName}
                    {
                        (user.token)
                            ? ` (${user.token})`
                            : null
                    }
                </Typography>

                {
                    (siteUser.rank == 'admin' || user.id == siteUser.id)
                        ? (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '12px' }}>
                                <Button 
                                    component="label"
                                    variant="contained" 
                                    size="small"
                                    startIcon={<CloudUpload />}
                                >
                                    Change avatar
                                    <VisuallyHiddenInput 
                                        type="file"
                                        onChange={(e) => {}}
                                    />
                                </Button>
                                <Button 
                                    component="label"
                                    variant="contained" 
                                    size="small"
                                    startIcon={<Edit />}
                                >
                                    Update profile
                                </Button>
                            </div>)
                        : null
                }

                <List style={{ width: '100%' }} aria-label="user-info">
                    <ListItem>
                        <ListItemAvatar><Avatar><Email /></Avatar></ListItemAvatar>
                        <ListItemText primary="Email" secondary={user.email} />
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar><Avatar><Phone /></Avatar></ListItemAvatar>
                        <ListItemText 
                            primary="Phone" 
                            secondary={(user.phone) ? user.phone : 'Not set'}
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar><Avatar><Person /></Avatar></ListItemAvatar>
                        <ListItemText 
                            primary="User type" 
                            secondary={
                                (user.rank == 'admin')
                                    ? 'Administrator'
                                    : (user.rank == 'siscot')
                                        ? 'Member'
                                        : 'Recruit'
                            }
                        />
                    </ListItem>
                </List>
            </CardContent>
        </Card>
    );
}

export default UserCard;