import { useContext, useState } from "react";
import Navbar from "../components/Navigation/Navbar";
import Login from "./Login";
import QuickFeedback from "../components/Feedback/QuickFeedback";
import { siteContext } from "../components/Utils/SiteContext";

function Homepage() {
    const { user } = useContext(siteContext);

    return <>
        <Navbar />
        <div id="homepage">
            <h1>Welcome back, {user.firstName}!</h1>

            <QuickFeedback />
        </div>
    </>;
}

export default Homepage;