import { Button, FormControl, Modal, Paper, Typography } from "@mui/material";
import "./FormModal.css";
import { cloneElement, useEffect, useState } from "react";

function FormModal({ name, title, active, setActive, children, onSubmit }) {
    const handleSubmit = (e) => {
        e.preventDefault();
        if(onSubmit) {
            onSubmit();
        }
    };

    return (
        <Modal open={active} onClose={() => { setActive(false); }}>
            <Paper className="form-modal">
                <Typography variant="h4" component="h4">{title}</Typography>
                <div className="form-modal-content">
                    <form id={`form-${name}`} onSubmit={handleSubmit}>
                        <FormControl fullWidth sx={{ width: '80%', gap: 4 }}>
                            {children}
                        </FormControl>
                        <Button variant="outlined" onClick={handleSubmit}>Submit</Button>
                    </form>
                </div>
            </Paper>
        </Modal>
    );
}

export default FormModal;
