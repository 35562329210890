import { AppBar, Avatar, Box, Button, IconButton, Link, Menu, Toolbar, Tooltip, Typography } from "@mui/material";
import { BACKEND_URL } from "../../Context";
import { useContext, useState } from "react";
import NavButton from "./NavButton";
import { MoreVert } from "@mui/icons-material";
import "./Navbar.css";
import { useNavigate } from "react-router-dom";
import AvatarButton from "./AvatarButton";
import { siteContext } from "../Utils/SiteContext";

function Navbar({}) {
    const { user, themeObject } = useContext(siteContext);
    const navigate = useNavigate();
    const [ anchorEl, setAnchorEl ] = useState(null);
    
    const buttons = [
        <NavButton name="Homepage" icon={"home"} destination="/" />,
        <NavButton name="Users" icon={"person"} destination="/users" />,
        <NavButton name="Teams" icon={"group"} destination="/teams" />,
        <NavButton name="Interviews" icon={"groups"} destination="/interviews" />,
        <NavButton name="Decisions" icon={"history"} destination="/decisions" />,
        <NavButton name="Phases" icon={"collections_bookmark"} destination="/phases" />,
        <NavButton name="Feedback" icon={"assignment"} destination="/feedback" />,
        <NavButton name="Statistics" icon={"show_chart"} destination="/statistics" />,
    ];

    return <>
        <AppBar position="static" color="primary">
            <Toolbar sx={{ justifyContent: 'space-between' }}>
                <Link 
                    component="button" 
                    variant="h6" 
                    underline="none" 
                    color="textSecondary"
                    onClick={() => navigate('/')}
                >
                    Banzai
                </Link>
                
                <Box id="navbar-buttons" sx={{ display: { xs: 'flex', md: 'none' } }}>
                    <Tooltip title="Pages">
                        <IconButton
                            aria-label="more"
                            aria-controls={(anchorEl) ? 'long-menu' : undefined}
                            aria-expanded={(anchorEl) ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={(e) => { setAnchorEl(e.currentTarget); }}
                        >
                            <MoreVert htmlColor="#fff" />
                        </IconButton>
                    </Tooltip>
                    <AvatarButton userId={user.id} />
                </Box>
                
                <Box id="navbar-btns-desktop" sx={{ 
                    display: { xs: 'none', md: 'flex' },
                    margin: 0
                }}>
                    {buttons}
                    <AvatarButton userId={user.id} />
                </Box>

                <Menu 
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}
                    open={(anchorEl) ? true : false}
                    onClose={(e) => { setAnchorEl(null); }}
                    sx={{ flexGrow: 1 }}
                >
                    {buttons}
                </Menu>

            </Toolbar>
        </AppBar>
    </>;
}

export default Navbar;