import { useContext, useEffect, useState } from "react";
import { BACKEND_URL, userContext } from "../Context";
import { Button, Paper, Typography } from "@mui/material";
import NewPhaseForm from "../components/Forms/NewPhaseForm";
import Navbar from "../components/Navigation/Navbar";
import "./Phases.css";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { Delete } from "@mui/icons-material";
import dayjs from "dayjs";

function Phases() {
    const user = useContext(userContext);
    const [ phases, setPhases ] = useState([]);
    const [ currentPhase, setCurrentPhase ] = useState();
    const [ isCreating, setIsCreating ] = useState(false);

    const loadCurrentPhase = async () => {
        try {
            const req = await fetch(`${BACKEND_URL}/api/phase`, { credentials: 'include' });
            const res = await req.json();
            setCurrentPhase({
                id: res.id,
                name: res.name,
                startDate: dayjs(res.startDate).format('dddd, DD MMMM YYYY'),
                endDate: dayjs(res.endDate).format('dddd, DD MMMM YYYY')
            });
        } catch(error) {
            console.error(error.message);
        }
    };

    const loadPhases = async () => {
        try {
            const req = await fetch(`${BACKEND_URL}/api/phase/all`, { credentials: 'include' });
            const list = await req.json();
            const _phases = list.map((elem) => {
                return {
                    id: elem.id,
                    name: elem.name,
                    startDate: dayjs(elem.startDate).format('dddd, DD MMMM YYYY'),
                    endDate: dayjs(elem.endDate).format('dddd, DD MMMM YYYY')
                }
            })
    
            setPhases(_phases);
        } catch(error) {
            console.error(error.message);
        }

        loadCurrentPhase();
    };

    useEffect(() => { loadPhases(); }, []);
    useEffect(() => { loadPhases(); }, [isCreating]);

    return <>
        <Navbar />
        <div id="phases-page">
            {
                (isCreating)
                    ? <NewPhaseForm active={isCreating} setActive={setIsCreating} />
                    : null
            }

            {
                (currentPhase && currentPhase.name != 'No phase')
                    ? <>
                        <div id="current-phase-div">
                            <Typography variant="h4">Current phase: <u>{currentPhase.name}</u></Typography>
                            <Typography variant="h5">{currentPhase.startDate} - {currentPhase.endDate}</Typography>
                        </div>
                    </>
                    : (<div id="current-phase-div"><Typography variant="h4">There is no current active phase.</Typography></div>)
            }

            {
                (user.rank == 'admin')
                    ? (<div><Button variant="contained" onClick={() => setIsCreating(true)}>Create new phase</Button></div>)
                    : null
            }

            <Paper sx={{ width: '90%', minHeight: '70vh' }}>
                <DataGrid
                    sx={{ border: 0 }}
                    columns={[
                        { headerName: 'ID', field: 'id', maxWidth: 70, type: 'number', flex: 1 },
                        { headerName: 'Name', field: 'name', minWidth: 130, flex: 1 },
                        { headerName: 'Start date', field: 'startDate', minWidth: 130, flex: 1 },
                        { headerName: 'End date', field: 'endDate', minWidth: 130, flex: 1 },
                        {
                            headerName: 'Actions',
                            type: 'actions',
                            field: 'actions',
                            minWidth: 100,
                            flex: 1,
                            getActions: (params) => [
                                <GridActionsCellItem
                                    icon={<Delete />}
                                    label="Delete phase"
                                    onClick={() => {
                                        if (user.rank == 'admin') {
                                            fetch(`${BACKEND_URL}/api/phase/${params.id}`, {
                                                credentials: 'include',
                                                method: 'DELETE'
                                            }).then(() => { loadPhases() });
                                        }
                                    }}
                                />
                            ]
                        }
                    ]}
                    rows={phases}
                    initialState={{ pagination: { page: 0, pageSize: 5 } }}
                />
            </Paper>
        </div>
    </>;
}

export default Phases;