import { FormControl, TextField } from "@mui/material";
import FormModal from "./FormModal";
import { useContext, useEffect, useState } from "react";
import { BACKEND_URL, userContext } from "../../Context";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { PhaseApi } from "../../Utils";
import { siteContext } from "../Utils/SiteContext";

function NewPhaseForm({ active, setActive }) {
    const { successToast, errorToast } = useContext(siteContext);

    const [ name, setName ] = useState('');
    const [ startDate, setStartDate ] = useState(dayjs());
    const [ endDate, setEndDate ] = useState(dayjs());

    const createPhase = async () => {
        const req = await PhaseApi.createPhase(name, startDate, endDate);

        if(req.status === 200) {
            successToast(`Phase "${name}" created.`);
        }
        else {
            errorToast(`Failed to create phase "${name}".`);
            console.error(await req.text());
        }

        setActive(false);
    };

    return (
        <FormModal name="newPhase" title="Create new phase" active={active} setActive={setActive} onSubmit={createPhase}>
            <FormControl fullWidth style={{ width: '80%', gap: '24px' }}>
                <TextField required label="Name" onChange={(e) => setName(e.target.value) } />
                <DatePicker required label="Start date" onChange={(e) => setStartDate(e)} />
                <DatePicker required label="End date" onChange={(e) => setEndDate(e)} />
            </FormControl>
        </FormModal>
    );
}

export default NewPhaseForm;